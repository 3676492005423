














































































































































































































































































































































































import { Observer } from "mobx-vue";
import { Component, Provide, Vue } from "vue-property-decorator";
import { SummonViewModel } from "@/modules/summon/viewmodels/summon-viewmodel";
import { walletStore } from "@/stores/wallet-store";

@Observer
@Component({
  components: {
    Ticket: () => import("@/modules/ticket/components/ticket.vue"),
    SummonDialog: () => import("@/modules/summon/components/summon-dialog.vue"),
    ConnectButton: () => import("@/components/ConnectButton.vue"),
  },
})
export default class SummonPage extends Vue {
  @Provide() vm = new SummonViewModel();
  premiumTicket = false;

  walletStore = walletStore;

  summon(isPremiumTicket) {
    this.vm.openSummonDialog(isPremiumTicket);
  }
}
