import { snackController } from "@/components/snack-bar/snack-bar-controller";
import { bigNumberHelper, bnHelper } from "@/helpers/bignumber-helper";
import { walletStore } from "@/stores/wallet-store";
import { FixedNumber } from "@ethersproject/bignumber";
import { action, computed, IReactionDisposer, observable, reaction, runInAction, when } from "mobx";
import { asyncAction, task } from "mobx-utils";
import moment from "moment";
import { Zero } from "@/constants";
import { Subject, timer } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { priceHelper } from "../../../helpers/pancakePriceHelper";
import { loadingController } from "@/components/global-loading/global-loading-controller";
import { TicketHandler } from "@/helpers/ticket-handler";
import { appProvider } from "@/app-providers";
import { NftModel } from "@/models/nft-model";

export class SummonViewModel {
  _disposers: IReactionDisposer[] = [];
  private _unsubcrible = new Subject();

  @observable isDialogLoading = false;
  @observable isSummonDialogLoading = false;
  @observable showSummonDialog = false;
  @observable isPremiumTicket = false;
  @observable nftLoading = false;

  @observable summonned = false;

  @observable premiumPrice = Zero;
  @observable standardPrice = Zero;
  @observable premiumTicketRate = "";
  @observable standardTicketRate = "";
  @observable nftPerTicket = "1";

  @observable approved = false;
  @observable approving = false;

  @observable userStandardTicketNo = Zero;
  @observable userPremiumTicketNo = Zero;
  @observable totalUsedTicket = Zero;
  @observable summonedNft?: NftModel;
  @observable summonedSuccess = true;
  @observable open = true;

  @observable dataLoading = false;

  ticketHandler?: TicketHandler;

  constructor() {
    this.loadData();
    this._disposers = [
      reaction(
        () => walletStore.account,
        () => {
          if (walletStore.isChainIdValid) this.ticketHandler?.injectMetamask(walletStore.web3!);
        }
      ),
    ];
  }

  destroy() {
    this._unsubcrible.next();
    this._unsubcrible.complete();
    this._disposers.forEach((d) => d());
  }

  async loadData() {
    const ticketHandler = new TicketHandler();
    this.ticketHandler = ticketHandler;
    if (walletStore.isMetamask) this.dataLoading = true;

    try {
      await Promise.all([ticketHandler.load()]);
      this.premiumPrice = this.ticketHandler.premiumPrice!;
      this.standardPrice = this.ticketHandler.standardPrice!;
      this.totalUsedTicket = this.ticketHandler.usedTicket!;
      this.premiumTicketRate = this.ticketHandler.premiumTicketRate;
      this.standardTicketRate = this.ticketHandler.standardTicketRate;
      this.nftPerTicket = this.ticketHandler.nftPerTicket;
    } catch (error) {
      console.error("loadData", error);
    }

    this._disposers.push(
      when(
        () => walletStore.connected,
        async () => {
          if (walletStore.chainId == process.env.VUE_APP_CHAIN_ID) {
            ticketHandler.injectMetamask(walletStore.web3!);
            ticketHandler
              .approvedLottery(walletStore.account)
              .then((approved) => runInAction(() => (this.approved = approved)));

            timer(0, 10000)
              .pipe(takeUntil(this._unsubcrible))
              .subscribe(async () => {
                this.fetchPoolInfo();
              });
          }
        }
      )
    );
    if (!walletStore.connected || !walletStore.isChainIdValid) this.dataLoading = false;
  }

  @asyncAction *fetchPoolInfo() {
    if (!walletStore.account || !this.ticketHandler) {
      //
    } else {
      const [{ standardTicketNo, premiumTicketNo }, totalUsedTicket] = yield Promise.all([
        this.ticketHandler?.getUserInfo(walletStore.account),
        this.ticketHandler.getUsedTicket(),
      ]);

      this.totalUsedTicket = totalUsedTicket;
      this.userPremiumTicketNo = premiumTicketNo;
      this.userStandardTicketNo = standardTicketNo;
    }
    this.dataLoading = false;
  }

  @asyncAction *approve() {
    this.approving = true;
    try {
      yield this.ticketHandler!.approveLottery(walletStore.account);
      this.approved = true;
    } catch (error) {
      snackController.error(error.message);
    }
    this.approving = false;
  }

  @asyncAction *spin() {
    this.isSummonDialogLoading = true;
    let spinSuccess = false;
    let summonedId = "";
    try {
      if (this.isPremiumTicket) {
        summonedId = yield this.ticketHandler?.premiumSpin(walletStore.account);
      } else {
        summonedId = yield this.ticketHandler?.standardSpin(walletStore.account);
      }

      spinSuccess = true;
      this.fetchPoolInfo();
      return {
        spinSuccess,
      };
    } catch (error) {
      spinSuccess = false;
      console.error(error);
      snackController.error(error.message);
      return {
        spinSuccess,
      };
    } finally {
      if (spinSuccess) {
        this.nftLoading = true;
        if (summonedId && summonedId !== "0") {
          const res = yield Promise.all([appProvider.api.getNFT(summonedId), this.sleep(2000)]);
          this.summonedNft = res[0] as NftModel;
        } else {
          yield this.sleep(2000);
          this.summonedSuccess = false;
        }
        this.nftLoading = false;
        this.showOpenTicket(false);
      }

      this.isSummonDialogLoading = false;
    }
  }

  async sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  @action.bound openSummonDialog(isPremiumTicket) {
    this.isPremiumTicket = isPremiumTicket;
    this.showSummonDialog = true;
  }
  @action.bound closeSummonDialog() {
    this.showSummonDialog = false;
    this.summonedNft = undefined;
    this.open = true;
    this.summonedSuccess = true;
  }

  @action.bound showOpenTicket(val) {
    this.open = val;
  }

  @computed get hasTicket() {
    return bnHelper.gt(this.userPremiumTicketNo, Zero) || bnHelper.gt(this.userStandardTicketNo, Zero);
  }
  @computed get canStandardSpin() {
    return bnHelper.gt(this.userStandardTicketNo, Zero);
  }
  @computed get canPremiumSpin() {
    return bnHelper.gt(this.userPremiumTicketNo, Zero);
  }
}
